import React from "react";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { Link, injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Section from "../../components/Section";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import Button from "../../components/Button";
import Questions from "../../components/Questions";
import customBlogLink from "../../utils/custom-blog-link";

import styles from "./index.module.scss";
import categoryBusinessIcon from "../../img/blog-category-business.jpg";
import categoryP2PIcon from "../../img/blog-category-p2p.jpg";
import categoryTuitionIcon from "../../img/blog-category-tuition.jpg";
import categoryNewsIcon from "../../img/blog-category-news.jpg";

const Blog = ({ data, intl }) => {
  const { prismic } = data;
  const posts = [];
  let categoryIcon;

  // Build posts
  for (const [index, post] of prismic.allBlog_posts.edges.entries()) {
    categoryIcon = () => {
      switch (post.node.category) {
        case "Business":
          return categoryBusinessIcon;
        case "P2P":
          return categoryP2PIcon;
        case "Tuition":
          return categoryTuitionIcon;
        case "News":
          return categoryNewsIcon;
        default:
          console.log("No icon");
      }
    };

    posts.push(
      <Col className={styles.postColumn} xs={12} sm={6} lg={4} key={index}>
        <article className={styles.post}>
          <div className={styles.postContent}>
            <div className={styles.postMeta}>
              <img src={categoryIcon()} width="31" height="31" alt="" />
              <div className={styles.categoryDateWrapper}>
                <div className={styles.category}>{post.node.category}</div>
                <div className={styles.date}>{post.node.date}</div>
              </div>
            </div>

            <Link to={`/blog/` + post.node._meta.uid}>
              <h2 className={styles.postHeading}>{post.node.title}</h2>
            </Link>
            <div className={styles.excerpt}>
              <RichText
                render={post.node.excerpt}
                serializeHyperlink={customBlogLink}
              />
            </div>
          </div>
          <Button
            className={styles.readMore}
            to={`/blog/` + post.node._meta.uid}
          >
            Read more
          </Button>
        </article>
      </Col>
    );
  }

  return (
    <Layout>
      <Background>
        <Hero>
          <MotifColumn>
            <h1>{intl.formatMessage({id: 'blog.hero.title'})}</h1>
            <p>
            {intl.formatMessage({id: 'blog.hero.text'})}
            </p>
          </MotifColumn>
        </Hero>
        <Section>
          <Row>{posts}</Row>
        </Section>
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(Blog);

export const pageQuery = graphql`
  query {
    prismic {
      allBlog_posts (sortBy: meta_firstPublicationDate_DESC, after:"YXJyYXljb25uZWN0aW9uOjA", first: 50){
        edges {
          node {
            _meta {
              firstPublicationDate
              uid
            }
            title
            date
            excerpt
            author
            category
          }
          cursor
        }
      }
    }
  }
`;
